import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import AOS from "aos";
import axios from "axios";
import { Footer } from "../components/Home_element";

export default function Score(props) {
    //   const mid = props.mid;
    const { matchId, shooterNumber } = useParams();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [matchInfo, setMatchInfo] = useState();
    const [dataSet, setDataSet] = useState([]);
    const [shooterScore, setShooterScore] = useState();
    const [shooterStage, setShooterStage] = useState();

    const getShooterScore = (matchId, shooterNumber) => {
        console.log("axos shooterNumber: ", JSON.stringify(shooterNumber));
        const instance = axios.create({
            baseURL: "https://api.idpasoth.com/stat/",
            headers: {
                Authorization:
                    "Bearer 50d819e4d51dfc02e36bac6f27e9c3a9227f688f",
                method: "shooterScore",
                mid: matchId,
                shooter_number: shooterNumber,
            },
        });
        instance
            .get()
            .then((res) => {
                console.log("shooter score loaded: ", res);
                // setMatchInfo(res.data);
                setShooterScore(res.data);
                setShooterStage(res.data.stage_score);
                setDataLoaded(true);
            })
            .catch((err) => {
                console.log("axios score err: ", err);
            });
    };

    function StageTable() {
        return (
            <Table
                responsive
                // striped
                // hover
                bordered
                variant="dark"
                style={{ fontSize: "1em", marginBottom: "0" }}
            >
                <thead>
                    <tr
                        className="text-center table-secondary"
                        style={{ fontSize: "1.2em" }}
                    >
                        <th style={{ minWidth: "70px" }}>Stage</th>
                        <th style={{ minWidth: "70px" }}>Final</th>
                        <th style={{ minWidth: "50px" }}>PD</th>
                        <th style={{ minWidth: "50px" }}>PE</th>
                        <th style={{ minWidth: "50px" }}>HNT</th>
                        <th style={{ minWidth: "70px" }}>Time</th>
                    </tr>
                </thead>
                <tbody>
                    {shooterStage.map((row, key) => (
                        <tr
                            key={key}
                            className="text-center hoverPrimary"
                            style={{ fontSize: "1.1em" }}
                        >
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_number}
                            </td>
                            <td
                                style={{ whiteSpace: "unset" }}
                                className="py-2"
                            >
                                {row.score_stage_time}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_PD}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_PE}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_HNT}
                            </td>
                            <td style={{ whiteSpace: "unset" }}>
                                {row.score_stage_string_1}
                            </td>
                        </tr>
                    ))}
                    <tr
                        className="text-center table-success fw-bold"
                        style={{ fontSize: "1.2em" }}
                    >
                        <td style={{ whiteSpace: "unset" }}>Total</td>
                        <td style={{ whiteSpace: "unset" }} className="py-2">
                            {shooterScore.shooter_total_score}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.shooter_PD}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.shooter_PE}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.shooter_HNT}
                        </td>
                        <td style={{ whiteSpace: "unset" }}>
                            {shooterScore.total_stage_string_1}
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function MyScore() {
        console.log("call My score");
        console.log("shooterNumber:", shooterNumber);

        return shooterScore ? (
            <>
                {/* my score */}
                {/* {"Stage_score:" + JSON.stringify(shooterStage)} */}
                <div id="shooterInfo" className="myScoreHolder">
                    {/* <div>{shooterScore.shooter_number}</div>
          <div>{shooterScore.shooter_idpa}</div>
          <div>{shooterScore.shooter_firstName}</div>
          <div>{shooterScore.shooter_lastName}</div>
          <div>{shooterScore.shooter_squad}</div>
          <div>{shooterScore.shooter_division}</div>
          <div>{shooterScore.shooter_class}</div>
          <div>{shooterScore.shooter_categories}</div>
          <div>{shooterScore.shooter_completed}</div>
          <div>{shooterScore.shooter_total_score}</div>
          <div>{shooterScore.shooter_PD}</div>
          <div>{shooterScore.shooter_HNT}</div>
          <div>{shooterScore.shooter_lastupdate}</div> */}
                    <div>
                    <span className="text-light fs-5">Place. #<span className="text-warning me-3 fs-3">{`${shooterScore.shooter_place}`}</span></span>
                    </div>
                    <div>
                        <span className="text-light fs-5">#</span>
                        <span className="text-warning me-3 fs-5">{`${shooterScore.shooter_number}`}</span>
                        <span className="text-light me-3 fs-4">{`${shooterScore.shooter_firstName}  ${shooterScore.shooter_lastName}`}</span>
                        {shooterScore.shooter_idpa ? (
                            <span className="text-warning me-3 fs-6">{`[${shooterScore.shooter_idpa}]`}</span>
                        ) : null}
                    </div>
                    <div>
                        <span className="text-light fs-5">SQD:</span>
                        <span className="text-warning me-3 fs-5">{`${shooterScore.shooter_squad}`}</span>
                        <span className="text-light me-3 fs-4">{`${shooterScore.shooter_division} / ${shooterScore.shooter_class}`}</span>
                        {shooterScore.shooter_categories ? (
                            <span className="text-light me-3 fs-7">{`[${shooterScore.shooter_categories}]`}</span>
                        ) : null}
                    </div>
                    <StageTable />
                </div>
            </>
        ) : null;
    }

    useEffect(() => {
        getShooterScore(matchId, shooterNumber);

        AOS.init();
        // console.log("dataset: ", dataSet);
        // eslint-disable-next-line
    }, [dataLoaded]);


    return (
        <div className="bg-dark pt-5">
            <div className="container mb-5">
                {MyScore()}
            </div>
            <div className="sticky-bottom">
                <Footer />
            </div>
        </div>
    );
}
